export const PORTAL_CONSTANT = {

  // 페이지네이션 버튼 갯수, 목록이 많은 경우에는 10 으로 ( default = FIVE)
  PAGINATION_VISIBLE_BUTTONS_CNT: {
    FIVE: 5,
    TEN: 10,
  },

  BOARD_TYPE: [
    {NAME: "일반", TYPE: "BOARD_TYPE_FLAG_GENERAL"},
    {NAME: "공지사항", TYPE: "BOARD_TYPE_FLAG_NOTICE"},
    {NAME: "자료실", TYPE: "BOARD_TYPE_FLAG_DATA"},
    {NAME: "Q&A", TYPE: "BOARD_TYPE_FLAG_QUESTION"},
    {NAME: "갤러리", TYPE: "BOARD_TYPE_FLAG_IMAGE"},
    //{ NAME : "동영상", TYPE : "M" },
  ],

  BOARD_OID: {

    NOTICE: {
      OID: "00000000001",
      NAME: "공지사항",
      TYPE: "N",
    },
    DATA: {
      OID: "00000000002",
      NAME: "자료실",
      TYPE: "D"
    },
    CUSTOM_BOARD1: {
      OID: "00000000003",
      NAME: "",
      TYPE: ""
    },
    ONE_TO_ONE_INQUIRY: {
      OID: "00000000004",
      NAME: "1:1 문의하기",
      TYPE: "Q"
    },
  },

  TIME_LIST: {
    A: {VALUE: "오전", KEY: "A"},
    P: {VALUE: "오후", KEY: "P"},
    D: {VALUE: "종일", KEY: "D"},
  },

  FLAG_YN: {

    YES: "Y",
    NO: "N",
  },

  FLAG_YN_WITH_LABEL1: {

    Y: {
      ASSIGNED: "해당 아이디는 사용 중입니다.",
    },
    N: {
      ASSIGNED: "사용 가능",
    },
  },

  ROLE_OID: {

    STUDENT: {

      NAME: "학생",
      ROLE_OID: "ROLE0000001",
    },
    PARENT: {

      NAME: "학부모",
      ROLE_OID: "ROLE0000002",
    },
    STAFF: {

      NAME: "교직원",
      ROLE_OID: "ROLE0000003",
    },
    STAFF_GANGWON: {

      NAME: "교직원(NEIS)",
      ROLE_OID: "ROLE0000004",
    },
    NORMAL: {

      NAME: "일반",
      ROLE_OID: "ROLE0000005",
    },
    MANAGER: {

      NAME: "관리자 그룹",
      ROLE_OID: "ROLE0000006",
    },
    ONTACT_MANAGER: {

      NAME: "원격교육연수원 관리자",
      ROLE_OID: "ROLE0000007",
    },
    CONTACT_MANAGER: {

      NAME: "블렌디드 러닝 연수 시스템 관리자",
      ROLE_OID: "ROLE0000008",
    },
    MATH_MANAGER: {

      NAME: "수리과학정보체험센터 관리자",
      ROLE_OID: "ROLE0000009",
    },
    BEFORE_LOGIN: {

      NAME: "로그인전",
      ROLE_OID: "ROLE0000010",
    },

    NOTE_VERIFY_USER: {

      NAME: "노트 검증위원 그룹",
      ROLE_OID: "ROLE0000011",
    },

  },

  // 로그인타입 : 일반 로그인, 일회용 로그인
  LOGIN_TYPE: {
    NORAML: "normal",
    OTP: "otp",
  },

  OTP_TYPE_FLAG: {
    LOGIN: "L",
    SELF_PHONE_CERT: "S",
  },

  ENUM_STATUS_TYPE_FLAG: {
    // 공통
    USE: {
      KEY: "USE",
      VALUE: "U",
      NAME: "운영",
      ICON_CLASS: "",
    },
    NOT_USE: {
      KEY: "NOT_USE",
      VALUE: "N",
      NAME: "숨김",
      ICON_CLASS: "",
    },

    CLOSE: {
      KEY: "CLOSE",
      VALUE: "X",
      NAME: "삭제",
      ICON_CLASS: "",
    },
    // 관리자
    WAIT: {
      KEY: "WAIT",
      VALUE: "W",
      NAME: "대기",
      ICON_CLASS: "label-doing",
    },
    ING: {
      KEY: "ING",
      VALUE: "I",
      NAME: "진행",
      ICON_CLASS: "label-waiting",
    },
    DONE: {
      KEY: "DONE",
      VALUE: "D",
      NAME: "완료",
      ICON_CLASS: "label-done",
    },
    STOP: {
      KEY: "STOP",
      VALUE: "S",
      NAME: "숨김",
      ICON_CLASS: "label-eduCancel",
    },
    // 사용자
    CANCEL: {
      KEY: "CANCEL",
      VALUE: "C",
      NAME: "취소",
      ICON_CLASS: "label-cancel",
    },
    APPROVAL: {
      KEY: "APPROVAL",
      VALUE: "A",
      NAME: "승인",
      ICON_CLASS: "label-approval",
    },
    REJECT: {
      KEY: "REJECT",
      VALUE: "R",
      NAME: "거부",
      ICON_CLASS: "label-refusal",
    },
    ERROR: {
      KEY: "ERROR",
      VALUE: "Z",
      NAME: "에러",
      ICON_CLASS: "label-refusal",
    },
    // 관리자 > 단축 URL > 공개된 URL 관리 에서 사용
    BLOCK: {
      KEY: "BLOCK",
      VALUE: "B",
      NAME: "차단",
      ICON_CLASS: "label-refusal",
    },
  },

  STATS_TYPE_FLAG: {
    LOGIN: {
      KEY: "STATS_TYPE_FLAG_LOGIN",
      NAME: "접속"
    },
    JOIN: {
      KEY: "STATS_TYPE_FLAG_JOIN",
      NAME: "가입"
    },
    WITHDRAWAL: {
      KEY: "STATS_TYPE_FLAG_WITHDRAWAL",
      NAME: "탈퇴"
    }
  },

  STATS_DATE_TYPE_FLAG: {
    YEAR: {
      KEY: "STATS_DATE_TYPE_FLAG_YEAR",
      NAME: "년"
    },
    MONTH: {
      KEY: "STATS_DATE_TYPE_FLAG_MONTH",
      NAME: "월"
    },
    DAY: {
      KEY: "STATS_DATE_TYPE_FLAG_DAY",
      NAME: "일"
    }
  },

  USER_STATUS_TYPE_FLAG: {
    U: {
      KEY: "USE",
      NAME: "가입",
    },
    O: {
      KEY: "DORMANT",
      NAME: "휴면",
    },
    L: {
      KEY: "WITHDRAWAL",
      NAME: "탈퇴",
    },
    G: {
      KEY: "WITHDRAWAL_FORCE",
      NAME: "강제탈퇴",
    },
    H: {
      KEY: "WITHDRAWAL_AUTO",
      NAME: "자동탈퇴",
    },
    C: {
      KEY: "CANCEL",
      NAME: "승인취소",
    },
    W: {
      KEY: "WAIT",
      NAME: "가입대기",
    },
    N: {
      KEY: "NOT_USE",
      NAME: "사용중지",
    },
    F: {
      KEY: "LOGIN_FAIL_COUNT",
      NAME: "연속 로그인 실패 정지",
    },
    X: {
      KEY: "CLOSE",
      NAME: "통합"
    },
    // 로그인 블락
    K: {
      KEY: "LOGIN_BLOCK",
      NAME: "차단",
    }

  },

  MENU_OBJECT_TYPE: "FWMN",
  BOARD_OBJECT_TYPE: "FWBO",
  CONTENTS_OBJECT_TYPE: "FWCO",

  MENU_TYPE_FLAG: {
    EMPTY: {
      KEY: "MENU_TYPE_FLAG_EMPTY",
      NAME: "없음",
    },

    URL: {
      KEY: "MENU_TYPE_FLAG_URL",
      NAME: "URL",
    },

    SCREEN: {
      KEY: "MENU_TYPE_FLAG_SCREEN",
      NAME: "화면",
    },

    BOARD: {
      KEY: "MENU_TYPE_FLAG_BOARD",
      NAME: "게시판",
    },

    MIX: {
      KEY: "MENU_TYPE_FLAG_MIX",
      NAME: "혼합형"
    },

    IFRAME: {
      KEY: "MENU_TYPE_FLAG_IFRAME",
      NAME: "외부시스템( iframe )"
    },
  },

  MENU_CLASS_NAME: {
    USER_MENU: {
      KEY: "userMenu",
      NAME: "사용자메뉴"
    },
    ADMIN_MENU: {
      KEY: "adminMenu",
      NAME: "관리자메뉴"
    }
  },

  POPUP_TYPE: {

    VIEW: {

      LIST: {

        KEY: "L",
        VALUE: "리스트",
      },
      GENERAL: {
        KEY: "G",
        VALUE: "일반",
      }
    },

    CONTENTS: {

      IMAGE: "I",
      EDITOR: "E"
    },

    LINK: {

      NEW: "N",
      PAGE: "P",
    },

    OPEN_WINDOW_YN: {

      YES: "Y",
      NO: "N",
    },

    MOVE: {

      IMAGE: "I",
      URL: "U",
      NO:  "N"
    }
  },

  POSTING_ACTION_FLAG: {

    ACTION_FLAG_INSERT: "ACTION_FLAG_INSERT", //생성
    ACTION_FLAG_CANCEL: "ACTION_FLAG_CANCEL", //삭제
  },

  POSTING_SEARCH_TYPE: {
    TITLE      : "FWPOSQ01",                         // 제목 검색
    INPUT_USER : "FWPOSQ03",                         // 등록자 검색
    DEL_USER   : "FWPOSQ08",                         // 삭제자 검색
  },

  FILE_TYPE: {
    GENERAL: "FWFT00GL", // 일반 첨부파일 리스트
    CONTENTS: "FWFT00CO", // 본문 컨텐츠의 파일 리스트
    HTML5: "FWFT00H5", // HTML5 파일리스트
    IMG: "FWFT0IMG", // 이미지 파일리스트
    MP4: "FWFT0MP4", // MP4 파일리스트
    MP4_THUMBNAIL: "FWFTMP4T", // MP4 썸네일
    APP: "FWFT0001",// 파일타입 앱
    APP_ICON: "FWFT0002",// 파일타입 앱 썸네일
    EXPLAIN_IMAGE: "FWFT0003",// 파일타입 앱 설명
  },

  VIDEO_MAIN_IMAGE: {
    FIRST: "F",          // 영상 첫 화면 사용
    THUMBNAIL: "T",      // 썸네일 이미지 등록록
  },

  MESSAGE_TYPE_MODE: {
    EMAIL: 32,
    SMS: 16,
    NOTI_TALK: 4,
  },

  MESSAGE_CLASS_TYPE: {

    SEND_MESSAGE_MANAGER: "SENDMNGR",          // 관리자 - 교육포털 - 메시지 발송 관리 - SMS/알림톡 발송 , EMAIL 발송
  },

  MESSAGE_CLASS_TYPE_PREFIX: {

    NOTI_TALK: "PTAL",
    MAIL: "PTMA",
  },

  MAIL_CLASS_TYPE: [
    {
      KEY: "PTMAJOIN",
      VALUE: "신규 가입 안내"
    },
    {
      KEY: "PTMAJOAU",
      VALUE: "회원 가입 인증"
    },
    {
      KEY: "PTMABFDT",
      VALUE: "휴면 처리 사전"
    },
    {
      KEY: "PTMAATDT",
      VALUE: "휴면 처리 완료"
    },
    {
      KEY: "PTMADLDT",
      VALUE: "일반 계정 전환"
    },
    {
      KEY: "PTMABFWD",
      VALUE: "자동 탈퇴 사전"
    },
    {
      KEY: "PTMAATWD",
      VALUE: "자동 탈퇴 완료"
    },
    {
      KEY: "PTMALOPW",
      VALUE: "비밀번호 변경 인증"
    },
    {
      KEY: "SENDMNGR",
      VALUE: "관리자 발송"
    },
    {
      KEY: "PTMAPRPO",
      VALUE: "개인정보 처리 방침"
    }
  ],

  MESSAGE_SEND_TYPE_FLAG: {

    USER: {
      KEY: "U",
      VALUE: "개별발송",
    },
    SYSTEM: {
      KEY: "S",
      VALUE: "자동발송",
    }
  },

  AUTO_SEND_TYPE_FLAG: {
    SMS: "S",
    NOTI_TALK: "N",
  },

  PORTAL_SYSTEM_MANAGER: {
    NAME: "포탈 시스템 관리자",
    EMAIL: "maengc@korea.kr",
  },

  MATH_EDU_TYPE_LIST: [
    {
      KEY: "STUDENT",
      VALUE: "STU",
      LABEL: "학생",
    },
    {
      KEY: "CIRCLE",
      VALUE: "CIR",
      LABEL: "동아리"
    },
    {
      KEY: "TEACHER",
      VALUE: "TCH",
      LABEL: "교사",
    },
    {
      KEY: "FAMILY",
      VALUE: "FAM",
      LABEL: "가족"
    },
  ],

  MATH_EDU_TYPE_OBJECT: {

    STUDENT: {
      KEY: "STUDENT",
      LABEL: "학생",
    },
    CIRCLE: {
      KEY: "CIRCLE",
      LABEL: "동아리",
    },
    TEACHER: {
      KEY: "TEACHER",
      LABEL: "교사",
    },
    FAMILY: {
      KEY: "FAMILY",
      LABEL: "가족",
    },
    COMMON: {
      KEY: "COMMON",
      LABEL: "공통(학생)",
    },
  },

  // 팝업 (시작) ------------------------------------>
  // 쿠키 용도로 활용
  POPUP_HIDE_TODAY: "POPUP_HT_",
  POPUP_HIDE_TODAY_LIST: "POPUP_HT_LIST",

  // 팝업 (종료) -->

  // 기관 코드 관련 상수
  PORTAL_ORG_ENUM: {
    ORG_NAME: {
      KEY: "orgName",
      VALUE: "기관명",
    },
    ORG_CODE: {
      KEY: "orgCode",
      VALUE: "기관 코드",
    },
    DEPARTMENT_NAME: {
      KEY: "departmentName",
      VALUE: "소속교육청",
    },
    WORKSPACE_ORG_NAME: {
      KEY: "workspaceOrgName",
      VALUE: "워크스페이스",
    },
  },

  PORTAL_ORG_SEARCH_OPTION: [
    {
      value: "orgName",
      label: "기관명",
    },
    {
      value: "orgCode",
      label: "기관 코드",
    },
    {
      value: "departmentName",
      label: "소속교육청",
    },
    {
      value: "workspaceOrgName",
      label: "워크스페이스",
    },
  ],

  PORTAL_ORG_STATUS_RADIO: [
    //  1. 전체 2.사용 3.미사용
    {
      value: '',
      label: "전체",
    },
    {
      value: 'Y',
      label: "사용",
    },
    {
      value: 'N',
      label: "미사용",
    },
  ],

  PORTAL_USE_YN_STATUS: {
    Y: {
      VALUE: "U",
      NAME: "사용",
    },
    N: {
      VALUE: "N",
      NAME: "미사용"
    },
  },

  PORTAL_ORG_CODE_TYPE: {
    ORG_AREA: {
      VALUE: 'ORG_AREA',
    },
    ORG_TYPE: {
      VALUE: 'ORG_TYPE',
    },
    ORG_CONTROL: {
      VALUE: 'ORG_CONTROL',
    },
  },

  PORTAL_ORG_DEPARTMENT: {
    ORG_SCIENCE_INFO: {
      HOMEPAGE_GROUP_OID : "1T2OloWz16e",
      VALUE: '과학정보부',
    },
    ORG_CREATIVE_FUSION: {
      HOMEPAGE_GROUP_OID : "1T2OloWz17L",
      VALUE: '창의융합팀',
    },
    ORG_EDU_INFO: {
      HOMEPAGE_GROUP_OID : "1T2OloWz1JH",
      VALUE: '교육정보팀',
    },
    ORG_GEN_AFFAIRS: {
      HOMEPAGE_GROUP_OID : "2TEw9l7gaDe",
      VALUE: '총무부',
    },
    ORG_INFO_SUPPORT: {
      HOMEPAGE_GROUP_OID : "2TEw9l7gaE4",
      VALUE: '정보지원과',
    },
    ORG_INFO_OPERATION: {
      HOMEPAGE_GROUP_OID : "1TG5p3fYZpb",
      VALUE: '정보운영팀',
    },
    ORG_CLOUD: {
      HOMEPAGE_GROUP_OID : "1TG5p3fYZpp",
      VALUE: '클라우드팀',
    },
    ORG_CYBER_SECURITY: {
      HOMEPAGE_GROUP_OID : "1TG5p3fYZqV",
      VALUE: '사이버보안팀',
    },
    ORG_BUSINESS_PORTAL: {
      HOMEPAGE_GROUP_OID : "1TG5p3fYZqb",
      VALUE: '업무포털팀',
    },
    ORG_GEN_AFFAIRS_DIV: {
      HOMEPAGE_GROUP_OID : "2TEw9l7gaEs",
      VALUE: '총무과',
    },
    ORG_GEN_AFFAIRS_TEAM: {
      HOMEPAGE_GROUP_OID : "1TG5p3fYZq2",
      VALUE: '총무팀',
    },
    ORG_CHUNCHEON: {
      HOMEPAGE_GROUP_OID : "1T2n1L7v040",
      VALUE: '춘천분원',
    },
    ORG_DONGHAE: {
      HOMEPAGE_GROUP_OID : "1T2n1L7v06e",
      VALUE: '동해분원',
    }
  },

  // HOMEPAGEGROUPuser 사용여부
  PORTAL_HOMEPAGEGROUPUSER_USEYN: {
    YES: "Y",
    NO: "N",
  },

  // HOMEPAGEGROUP 인지 HOMEPAGEGROUPuser인지 사용여부
  PORTAL_HOMEPAGEGROUP_OR_HOMEPAGEGROUPUSER: {
    HOMEPAGEGROUP: "HOMEPAGEGROUP",
    HOMEPAGEGROUPUSER: "HOMEPAGEGROUPUSER",
  },

  // insert인지 update인지 사용여부
  PORTAL_HOMEPAGEGROUPUSER_INSERT_OR_UPDATE: {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
  },

  // flag 값으로 메뉴 타입을 구할 때 사용
  getMenuTypeFlagName( menuTypeFlag ) {

    switch (menuTypeFlag) {

      case this.MENU_TYPE_FLAG.EMPTY.KEY:
        return this.MENU_TYPE_FLAG.EMPTY.NAME;

      case this.MENU_TYPE_FLAG.URL.KEY:
        return this.MENU_TYPE_FLAG.URL.NAME;

      case this.MENU_TYPE_FLAG.SCREEN.KEY:
        return this.MENU_TYPE_FLAG.SCREEN.NAME;

      case this.MENU_TYPE_FLAG.BOARD.KEY:
        return this.MENU_TYPE_FLAG.BOARD.NAME;

      case this.MENU_TYPE_FLAG.MIX.KEY:
        return this.MENU_TYPE_FLAG.MIX.NAME;

      default:
        return "";
    }
  },

  // flag 값으로 statusTypeFlag 타입을 구할 때 사용
  getUserStatusTypeFlagName( statusTypeFlag ) {

    switch (statusTypeFlag) {

      case this.USER_STATUS_TYPE_FLAG.U.KEY:
        return this.USER_STATUS_TYPE_FLAG.U.NAME;

      case this.USER_STATUS_TYPE_FLAG.O.KEY:
        return this.USER_STATUS_TYPE_FLAG.O.NAME;

      case this.USER_STATUS_TYPE_FLAG.L.KEY:
        return this.USER_STATUS_TYPE_FLAG.L.NAME;

      case this.USER_STATUS_TYPE_FLAG.G.KEY:
        return this.USER_STATUS_TYPE_FLAG.G.NAME;

      case this.USER_STATUS_TYPE_FLAG.H.KEY:
        return this.USER_STATUS_TYPE_FLAG.H.NAME;

      case this.USER_STATUS_TYPE_FLAG.C.KEY:
        return this.USER_STATUS_TYPE_FLAG.C.NAME;

      case this.USER_STATUS_TYPE_FLAG.W.KEY:
        return this.USER_STATUS_TYPE_FLAG.W.NAME;

      case this.USER_STATUS_TYPE_FLAG.N.KEY:
        return this.USER_STATUS_TYPE_FLAG.N.NAME;

      case this.USER_STATUS_TYPE_FLAG.F.KEY:
        return this.USER_STATUS_TYPE_FLAG.F.NAME;

      case this.USER_STATUS_TYPE_FLAG.X.KEY:
        return this.USER_STATUS_TYPE_FLAG.X.NAME;

      case this.USER_STATUS_TYPE_FLAG.K.KEY:
          return this.USER_STATUS_TYPE_FLAG.K.NAME;

      default:
        return "";
    }
  },

  // 회원관리 - 수신동의 여부
  USER_SET_RECEIVE_EMAIL_YN: "receiveEmailYn",	        // 이메일 수신 동의 여부
  USER_SET_RECEIVE_SMS_YN: "receiveSmsYn",	            // SNS 수신 동의 여부

  // 회원관리 - 회원구분 - object 형
  USER_TYPE: {

    ADMIN: {
      KEY: "USST0000",
      LABEL: "관리자",
    },
    STUDENT: {
      KEY: "USST0001",
      LABEL: "학생",
    },
    PARENT: {
      KEY: "USPA0002",
      LABEL: "학부모",
    },
    STAFF: { // 교직원( 기존 ) - USSF
      KEY: "USSF0003",
      LABEL: "교직원",
    },
    STAFF_GANGWON: { // 강원교직원( 교직원 신규 ) - USSF prefix가 같음.
      KEY: "USSFGG04",
      LABEL: "교직원(강원)",
    },
    NORMAL: {
      KEY: "USNL0005",
      LABEL: "일반",
    },
  },

  STATS_SEARCH_TYPE: {
    YEAR: {
      KEY: 'YEAR',
      LABEL: "연도별검색",
    },
    MONTH: {
      KEY: 'MONTH',
      LABEL: "월별검색",
    },
    DAY: {
      KEY: 'DAY',
      LABEL: "일별검색",
    },
  },

  // 탭 메뉴
  TAB_MENU: {
    MENU_FIRST: "MENU_FIRST",
    MENU_SECOND: "MENU_SECOND",
    MENU_THIRD: "MENU_THIRD",
  },

  // 홈페이지 연혁 관리 및 주요업무 계획 관리에 쓰이는 객체 타입 상수
  PORTAL_HOMEPAGE_USE_TYPE: {
    HISTORY: {
      KEY: "FWHP0001",
      VALUE: "연혁"
    },
    MAINTASK: {
      KEY: "FWHP0002",
      VALUE: "주요업무"
    },
  },

  ORG_TYPE_DIRECT_INSTITUTE   : 'T0060', // 직속기관
  ORG_TYPE_EDU_OFFICE         : 'T0070', // 시도교육청
  ORG_TYPE_EDU_SUPPORT_OFFICE : 'T0080', // 교육지원청

  // 로그인 시 이메일 주소
  LOGIN_EMAIL_DOMAIN: [
    process.env.GOOGLE_WORKSPACE_DOMAIN,
    "@naver.com",
    "@hanmail.com",
    "@nate.com",
    "@gmail.com",
    "@daum.com",
  ],

  // 워크스페이스
  WORKSPACE_ID_VALIDATION: {

    DOMAIN: process.env.GOOGLE_WORKSPACE_DOMAIN,

    DOMAIN_SUB: "@on.gwedu.go.kr",

    INCOMPLETE: {
      KEY: 0,
      VALUE: "아이디를 모두 채워주세요."
    },
    AVAILABLE: {
      KEY: 1,
      VALUE: "사용 가능한 아이디입니다."
    },
    DUPLICATION: {
      KEY: 2,
      VALUE: "해당 아이디는 사용할 수 없습니다.(중복 아이디)"
    },
    NEED_VALIDATION: {
      KEY: 3,
      VALUE: "아이디 중복 검사가 필요합니다."
    },
    WRONG_DOMAIN: {
      KEY: 4,
      VALUE: "해당 아이디는 사용할 수 없습니다.(잘못된 도메인)"
    },
    WRONG_ID_FORMAT: {
      KEY: 5,
      VALUE: "해당 아이디는 사용할 수 없습니다.(잘못된 형식)"
    },
    DUPLICATION_IN_LIST: {
      KEY: 6,
      VALUE: "리스트 목록 내에 중복된 아이디가 존재합니다"
    },
    WRONG_PASSWORD_FORMAT: {
      KEY: 7,
      VALUE: `비밀번호 형식을 확인해주시기 바랍니다.`
    }

  },

  WORKSPACE_PASSWORD: {

    MIN_LENGTH: 8,

    DEFAULT_PASS: {
      GWEDU: {
        VALUE: "onGweduGoKr",
      },
    },

    VALIDATION: {
      INCOMPLETE: {
        KEY: 0,
        VALUE_8: "영문 대소문자/숫자/특수문자 중 2가지 이상 조합, 8 ~ 16자를 입력해주세요.",
        VALUE_10: "영문/숫자/특수문자를 모두 사용한 조합으로 10 ~ 16자를 입력해주세요.",
        // VALUE : "8자 이상입니다."
      },
      AVAILABLE: {
        KEY: 1,
        VALUE: ""
      },
    },
  },

  WORKSPACE_ORG_CONST: {
    ONE_DEPTH: "/",
    TWO_DEPTH: "/newportal",
    THREE_DEPTH: "/newportal",

    NO_ORG: '/[00000] 조직없음',
    NO_ORG_GWEDU: '/Remarkable(test)/조직없음',

    LAST_NODE: {
      STUDENT: {
        KEY: "std",
        ID_RULE: "S",
        PATH: "/std",
        DESCR: "학생의 최종 조직 경로",
      },
      TEACHER: {
        KEY: "tea",
        ID_RULE: "T",
        PATH: "/tea",
        DESCR: "교직원의 최종 조직 경로",
      },
      DEVICE: {
        KEY: "dev",
        ID_RULE: "D",
        PATH: "/dev",
        DESCR: "디바이스계정(기기)의 최종 조직 경로",
      },
    },
  },

  // 회원 계정 타입
  WORKSPACE_USER_TYPE_RADIO: {
    ALL: {
      KEY: "A",
      VALUE: "ALL",
      LABEL: "전체",
    },
    TEACHER: {
      KEY: "T",
      VALUE: "TEACHER",
      LABEL: "교직원",
    },
    STUDENT: {
      KEY: "S",
      VALUE: "STUDENT",
      LABEL: "학생",
    },
    DEVICE: {
      KEY: "D",
      VALUE: "DEVICE",
      LABEL: "디바이스"
    },
  },

  WORKSPACE_STATUS_RADIO: {
    ALL: {
      KEY: "A",
      VALUE: "ALL",
      LABEL: "전체",
    },
    ACTIVE: {
      KEY: "E", // A 중복으로 끝자리 E를 사용
      VALUE: "ACTIVE",
      LABEL: "활성",
    },
    SUSPENDED: {
      KEY: "S",
      VALUE: "SUSPENDED",
      LABEL: "일시정지",
    },
  },

  WORKSPACE_INSERT_STATUS: {
    S: {
      KEY: "S",
      VALUE: "SUCCESS",
      LABEL: "가입 성공",
    },
    F: {
      KEY: "F",
      VALUE: "FAIL",
      LABEL: "가입 실패",
    },
    W: {
      KEY: "W",
      VALUE: "WAIT",
      LABEL: "가입 대기",
    },
  },

  // 시스템 롤
  WORKSPACE_SYSTEM_ROLE: {

    _GROUPS_ADMIN_ROLE: {
      LABEL: "그룹스 관리자",
      DESCR: "Groups Administrator",
    },

    _SEED_ADMIN_ROLE: {
      LABEL: "최고 관리자",
      DESCR: "G Suite Administrator Seed Role",

    },

    _HELP_DESK_ADMIN_ROLE: {
      LABEL: "헬프 데스크 관리자",
      DESCR: "Help Desk Administrator",

    },

    _USER_MANAGEMENT_ADMIN_ROLE: {
      LABEL: "사용자 관리 관리자",
      DESCR: "User Management Administrator",

    },

    _SERVICE_ADMIN_ROLE: {
      LABEL: "서비스 관리자",
      DESCR: "",

    },

    _MOBILE_ADMIN_ROLE: {
      LABEL: "모바일 관리",
      DESCR: "",

    },

    _GROUPS_READER_ROLE: {
      LABEL: "그룹스 리더",
      DESCR: "",

      VER: "BETA",
    },

    _GROUPS_EDITOR_ROLE: {
      LABEL: "그룹스 편집자",
      DESCR: "",
      VER: "BETA",
    },
    _LEGACY_ENTERPRISE_SUPPORT_ROLE: {
      LABEL: "기존 엔터프라이즈 지원",
      DESCR: "",

    },

    _THIRD_PARTY_DEVICE_MANAGEMENT_ADMIN_ROLE: {
      LABEL: "타사 기기 관리 관리자",
      DESCR: "",

    },

    _GCDS_DIRECTORY_MANAGEMENT_ROLE: {
      LABEL: "GCDS_DIRECTORY 관리",
      DESCR: "",

    },

  },

  GOOGLE_AUDIT_ACTION_FALG: {
    INSERT: {
      KEY: "I",
      VALUE: "INSERT",
      LABEL: "생성"
    },
    STOP: {
      KEY: "S",
      VALUE: "STOP",
      LABEL: "일시정지"
    },
    ACTIVE: {
      KEY: "A",
      VALUE: "ACTION",
      LABEL: "활성화"
    },
    DELETE: {
      KEY: "D",
      VALUE: "DELETE",
      LABEL: "삭제"
    },
    RESTORE: {
      KEY: "R",
      VALUE: "RESTORE",
      LABEL: "복구"
    }
  },

  // passwordRadio
  WORKSPACE_PWD_RADIO: {
    PWD_AUTO_GEN : "auto",
    PWD_BATCH_GEN : "batch",
  },

  // 권한목록

  // 환경 설정 : ENV_CONFIG
  PORTAL_ENV_CONFIG: {

    USERS_PRESERVATION_PERIOD: {
      KEY: "system.user.policy.preservation.period",
      VALUE: "회원 이력 보존기간",
      DESCR: "설정된 기간 동안 회원 이력을 보관합니다.",
      PARENT_CONFIG: "users",
      SELECT_TYPE: "system.config.option.data.month",
      DEFAULT_VALUE: "12"
    },
    // 사용하지 않는 휴면 전환 주석 처리 (사용 시 활성화)
    // USERS_DORMANTCHANGE_PERIOD: {
    //   KEY: "system.user.policy.dormantchange.period",
    //   VALUE: "휴면 회원 전환",
    //   DESCR: "설정된 기간 동안  로그인하지 않은 회원은 자동으로 휴면회원으로 처리됩니다.",
    //   PARENT_CONFIG: "users",
    //   SELECT_TYPE: "input",
    //   DEFAULT_VALUE: "1"
    // },
    USERS_AUTOWITHDRAWAL: {
      KEY: "system.user.autowithdrwal.yn",
      VALUE: "자동 회원 탈퇴",
      DESCR: "설정된 기간 동안 로그인하지 않은 회원은 자동으로 탈퇴 및 개인 정보를 삭제합니다.</br>자동 삭제 1개월 전 자동 탈퇴 및 개인 정보 삭제 사전 안내 메일을 발송합니다.",
      PARENT_CONFIG: "users",
      SELECT_TYPE: "checkbox",
      DEFAULT_VALUE: "Y"
    },
    USERS_AUTOWITHDRAWAL_PERIOD: {
      KEY: "system.user.autowithdrawall.period",
      VALUE: "자동 회원 탈퇴 기간",
      DESCR: "회원 탈퇴 기준이 되는 미접속 최대 기간을 설정합니다. </br> 해당 기간이 경과하면 회원 계정이 자동으로 탈퇴 처리되고, 개인 정보가 삭제됩니다.",
      PARENT_CONFIG: "users",
      SELECT_TYPE: "select",
      DEFAULT_VALUE: "5"
    },
    USERS_PRIVACY_POLICY : {
      KEY : "system.user.send.privacypolicy.yn",
      VALUE : "개인 정보 처리 방침 자동 발송",
      DESCR : "회원 가입 날짜를 기준으로 설정된 주기에 따라 개인 정보 처리 방침 메일을 발송합니다.",
      PARENT_CONFIG : "users",
      SELECT_TYPE : "checkbox",
      DEFAULT_VALUE : "Y"
    },
    USERS_PRIVACY_POLICY_PERIOD : {
      KEY : "system.user.send.privacypolicy.period",
      VALUE : "개인 정보 처리 방침 발송 주기",
      DESCR : "개인 정보 처리 방침을 발송할 주기를 설정합니다.",
      PARENT_CONFIG : "users",
      SELECT_TYPE : "select",
      DEFAULT_VALUE : "1"
    },
    LOGIN_DUPLICATE_LOGIN_YN: {
      KEY: "system.login.duplicate.yn",
      VALUE: "중복 로그인 제한",
      DESCR: "사용 설정 시 동일 계정으로 중복 로그인을 제한합니다.</br>로그인 시 처음 로그인 된 사용자는 강제 로그아웃 됩니다.",
      PARENT_CONFIG: "login",
      SELECT_TYPE: "checkbox",
      DEFAULT_VALUE: "Y"
    },
    LOGIN_LOGINFAIL_COUNT: {
      KEY: "system.login.fail.count",
      VALUE: "로그인 실패 횟수 제한",
      DESCR: "설정된 횟수 이상 비밀번호를 틀렸을 경우 로그인이 차단 됩니다.</br>차단된 계정은 회원 관리 화면에서 비밀번호 초기화로 해제 가능합니다.",
      PARENT_CONFIG: "login",
      SELECT_TYPE: "system.config.option.login.fail",
      DEFAULT_VALUE: "5"
    },
    LOGIN_LOGINSESSION_TIME: {
      KEY: "system.login.session.time",
      VALUE: "로그인 유지 시간",
      DESCR: "로그인 후 설정된 시간 동안 아무런 동작이 없을 경우 자동 로그 아웃 됩니다.",
      PARENT_CONFIG: "login",
      SELECT_TYPE: "system.config.option.login.session",
      DEFAULT_VALUE: "120",
    },
    LOGIN_LOGINBLOCK_TIME: {
      KEY: "system.login.block.time",
      VALUE: "로그인 차단 시간",
      DESCR: "로그인 실패 횟수 초과했을시 로그인 차단시간",
      PARENT_CONFIG: "login",
      SELECT_TYPE: "system.config.common.login.block.time",
      DEFAULT_VALUE: "30",
    },
    PWD_USE_YN: {
      KEY: "system.pwd.change.notification.use.yn",
      VALUE: "사용 설정",
      DESCR: "사용 설정 시 회원이 로그인 했을 때 비밀번호 변경을 위한 안내 페이지가 보여집니다.",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "checkbox",
      DEFAULT_VALUE: "Y"
    },
    PWD_CHANGENOTIFICATION_PERIOD: {
      KEY: "system.pwd.change.notification.period",
      VALUE: "비밀번호 변경 주기",
      DESCR: "설정된 기간이 지나면 비밀번호 변경 안내 페이지가 보여집니다</br>(최종 변경일 기준)",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "system.config.option.data.month",
      DEFAULT_VALUE: "1"
    },
    PWD_CHANGENOTIFICATION_TYPE: {
      KEY: "system.pwd.change.notification.type",
      VALUE: "변경 안내 주기 타입",
      DESCR: "'다음에 변경하기' 선택 시 설정된 기간이 지난 후 부터 다시 비밀번호 안내 페이지가 보여집니다.",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "notUse",
      DEFAULT_VALUE: "1"
    },
    PWD_CHANGENOTIFICATION_DATE: {
      KEY: "system.pwd.change.notification.date",
      VALUE: "변경 안내 주기",
      DESCR: "'다음에 변경하기' 선택 시 설정된 기간이 지난 후 부터 다시 비밀번호 안내 페이지가 보여집니다.",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "system.pwd.change.notification",
      DEFAULT_VALUE: "1"
    },
    PWD_BEFOREPWD_USE_YN: {
      KEY: "system.pwd.before.use.yn",
      VALUE: "이전 비밀번호 재사용",
      DESCR: "허용 설정 시 직전 비밀번호로 비밀번호 변경이 가능합니다.",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "checkbox",
      DEFAULT_VALUE: "Y"
    },
    PWD_GENERATERULE: {
      KEY: "system.pwd.rule.type",
      VALUE: "비밀번호 생성 규칙",
      DESCR: "- 조합 없이 4 ~ 16자</br> - 영문 + 숫자 + 특수문자 조합, 8 ~ 16자</br> - 영문 + 숫자 + 특수문자 조합, 10 ~ 16자",
      PARENT_CONFIG: "pwd",
      SELECT_TYPE: "system.pwd.rule.type",
      DEFAULT_VALUE: "10~16"
    },
    AUTO_SEND_TYPE_FLAG: {
      KEY: "system.message.auto.send.flag"
    },
  },

  // 비밀번호 생성 규칙 설명문을 동적으로 넣기 위해 사용
  PWD_GEN_RULE_DESCR: {
    "4~16": {
      VALUE: "조합 없이 4 ~ 16자"
    },
    "8~16": {
      VALUE: "영문 + 숫자 + 특수문자 조합, 8 ~ 16자"
    },
    "10~16": {
      VALUE: "영문 + 숫자 + 특수문자 조합, 10 ~ 16자"
    }
  },

  // 각 시스템 OID ( = MENU_OID )
  SYSTEM_OID: {

    PORTAL: "PORTAL00000",        // 포털 시스템 최상위
    HOMEPAGE: "HOMEPAGE000",        // 포털 홈페이지
    LMS_CONTACT: "LMSFE000001",        // 교직원집합연수
    LMS_ONTACT: "LMSOE000001",        // 원격교육연수원
    EBOOK: "EBOOK000000",        // 전자책
    SURVEY: "SURVEY00000",        // 설문
    MATH: "MATH0000000",        // 수리과학정보체험센터
    NOTE: "NOTE0000000",        // 교수학습지원센터
    GOOGLE: "GOOGLE00000",        // 구글워크스페이스
    MENU_ADMIN_ROOT: "ADMINROOT00",        // 관리자 메뉴 루트
    MENU_USER_ROOT: "USERROOT000",        // 사용자 메뉴 루트
    ONLINE_APPLY: "APPLY000000",        // 온라인 참가신청시스템
    ONLINE_CONTEST: "CONTEST0000",        // 온라인 심사시스템

  },

  // 통합 팝업 등록 시스템 OID
  SYNC_POPUP_SYSTEM: {
    HOMEPAGE: {
      key: "HOMEPAGE000",
      value: "홈페이지",
      active: "home_favicon.png",
      inactive: "home_favicon_gray.png",
      attribute: "is-custom",
      alt: "홈페이지",
    },
    LMS_ONTACT: {
      key: "LMSOE000001",
      value: "원격교육연수원",
      active: "lms_favicon_01.png",
      inactive: "lms_favicon_gray_01.png",
      attribute: "lms-border__color",
      alt: "원격교육연수원",
    },
    LMS_CONTACT: {
      key: "LMSFE000001",
      value: "블렌디드러닝연수시스템",
      active: "lms_favicon.png",
      inactive: "lms_favicon_gray.png",
      attribute: "lms-border__color",
      alt: "블렌디드러닝연수시스템",
    },
    SURVEY: {
      key: "SURVEY00000",
      value: "설문",
      active: "survey_favicon.png",
      inactive: "survey_favicon_gray.png",
      attribute: "survey-border__color",
      alt: "설문",
    },
    EBOOK: {
      key: "EBOOK000000",
      value: "전자책",
      active: "eBook_favicon.png",
      inactive: "eBook_favicon_gray.png",
      attribute: "is-custom",
      alt: "전자책",
    },
    WONJU: {
      key: "AREA0000001",
      value: "제 1 수리과학정보체험센터",
      active: "math_favicon_01.svg",
      inactive: "math_favicon_gray_01.svg",
      attribute: "math-border__color",
      alt: "제 1 수리과학정보체험센터",
    },
    CHUNCHEON: {
      key: "AREA0000002",
      value: "제 2 수리과학정보체험센터",
      active: "math_favicon_02.svg",
      inactive: "math_favicon_gray_02.svg",
      attribute: "math-border__color",
      alt: "제 2 수리과학정보체험센터",
    },
    DONGHAE: {
      key: "AREA0000003",
      value: "제 3 수리과학정보체험센터",
      active: "math_favicon_03.svg",
      inactive: "math_favicon_gray_03.svg",
      attribute: "math-border__color",
      alt: "제 3 수리과학정보체험센터",
    },
    VACANCY: {
      key: "VACA0000000",
      value: "강원보결전담강사예약시스템",
      active: "protutor_favicon.png",
      inactive: "protutor_favicon_gray.png",
      attribute: "protutor-border__color",
      alt: "강원보결전담강사예약시스템",
    },
    NOTE: {
      key: "NOTE0000000",
      value: "GW노트+",
      active: "note_favicon.png", // 임시용 노트 파비콘으로 변경해야됨
      inactive: "note_favicon_gray.png",
      attribute: "note-border__color",
      alt: "GW노트+",
    },
    APPLY: {
      key: "APPLY000000",
      value: "온라인 참가신청시스템",
      active: "apply_favicon.png", // 임시용 노트 파비콘으로 변경해야됨
      inactive: "apply_favicon_gray.png",
      attribute: "apply-border__color",
      alt: "온라인 참가신청시스템",
    },
    CONTEST: {
      key: "CONTEST0000",
      value: "온라인 심사시스템",
      active: "contest_favicon.png", // 임시용 노트 파비콘으로 변경해야됨
      inactive: "contest_favicon_gray.png",
      attribute: "contest-border__color",
      alt: "온라인 심사시스템",
    },
    SHORTURL: {
      key: "SHORTURL000",
      value: "단축 URL 만들기",
      active: "shorturl_favicon.png", // 임시용 노트 파비콘으로 변경해야됨
      inactive: "shorturl_favicon_gray.png",
      attribute: "shorturl-border__color",
      alt: "단축 URL 만들기",
    },
  },

  SYNC_POSTING_SYSTEM: {
    HOMEPAGE: {
      key: "HOMEPAGE000",
      value: "홈페이지",
      active: "home_favicon.png",
      inactive: "home_favicon_gray.png",
      attribute: "is-custom",
    },
    LMS_ONTACT: {
      key: "LMSOE000001",
      value: "원격교육연수원",
      active: "lms_favicon_01.png",
      inactive: "lms_favicon_gray_01.png",
      attribute: "lms-border__color",
    },
    SURVEY: {
      key: "SURVEY00000",
      value: "설문",
      active: "survey_favicon.png",
      inactive: "survey_favicon_gray.png",
      attribute: "survey-border__color",
    },
    WONJU: {

      key: "AREA0000001",
      value: "제 1 수리과학정보체험센터",
      active: "math_favicon_01.svg",
      inactive: "math_favicon_gray_01.svg",
      attribute: "math-border__color",
    },
    CHUNCHEON: {
      key: "AREA0000002",
      value: "제 2 수리과학정보체험센터",
      active: "math_favicon_02.svg",
      inactive: "math_favicon_gray_02.svg",
      attribute: "math-border__color",
    },
    DONGHAE: {
      key: "AREA0000003",
      value: "제 3 수리과학정보체험센터",
      active: "math_favicon_03.svg",
      inactive: "math_favicon_gray_03.svg",
      attribute: "math-border__color",
    },
    VACANCY: {
      key: "VACA0000000",
      value: "강원보결전담강사예약시스템",
      active: "protutor_favicon.png",
      inactive: "protutor_favicon_gray.png",
      attribute: "protutor-border__color",
    },

    /** 추가 예정 시스템 */
    /*
    NOTE            : {
      key : "NOTE0000000",
      value : "교수학습지원센터",
    },
    */

  },

  // key(OID) 를 가지고 이름 찾기 위해 추가
  SYSTEM_OID_LIST: [
    {
      key: "PORTAL00000",
      value: "교육포털",
    },
    {
      key: "HOMEPAGE000",
      value: "홈페이지",
    },
    {
      key: "LMSFE000001",
      value: "블렌디드 러닝 연수 시스템",
    },
    {
      key: "LMSOE000001",
      value: "원격교육연수원",
    },
    {
      key: "EBOOK000000",
      value: "전자책",
    },
    {
      key: "SURVEY00000",
      value: "설문",
    },
    {
      key: "MATH0000000",
      value: "수리과학정보체험센터",
    },
    {
      key: "NOTE0000000",
      value: "교수학습지원센터",
    },
  ],

  // 교육연구 탭 이동 관련 상수
  EDU_SEARCH: {
    CONTEST_INTRO: {
      OID: '1Su56OeJ000',
      NAME: 'introduce',
      VALUE: '안내',
    },
    CONTEST_REF_ROOM: {
      OID: '1Sqy55lw003',
      NAME: 'referenceRoom',
      VALUE: '자료실',
    }
  },

  // 사용자 SMS 발송 관련 상수
  USER_SEND_SMS: {
    OID: '1TRr4lui0Fr',
    NAME: 'SMS발송',
  },

  MENU_OID: {
    SCI_EDU: '1Sr9XUT8000', // 과학교육(3depth)
    SCIENCE_EDU: '1Sr9XUT8001', // 과학교육 > 학생과학발명품 경진대회(4depth)
    SCIENCE_EXHIBITION: '1Sr9XUT8003', // 과학교육 > 과학전람회(4depth)

    INFO_EDU: '1Sr9XUT8009', // 정보교육(3depth)
    INFORMATION_EDU: '1Sr9XUT800A', // 정보교육 > 대회행사 공지사항(4depth)

    EDU_GENIUS_GW: '1Sr9XUT800D', // 강원영재교육(3depth)
    EDU_GENIUS: '1Sr9XUT800E', // 강원영재교육 > 교육소개(4depth)

    EDU_GENIUS_ACADEMY: '1Sr9XUT800H', // 정보영재교육원(3depth)
    CENTER_GENIUS: '1Sr9XUT800I', // 정보영재교육원 > 교육소개(4depth)

    CUSTOMER_SERVICE: '1Su5IAxV0KC', // 고객센터 > 개인정보처리 방침 > 개정수정이력
  },

  MENU_URL: {
    CENTER_GENIUS : '/portal/user/common/commonForm?menuOid=1Sr9XUT800I&menuTypeFlag=MENU_TYPE_FLAG_MIX', // 교육활동 > 정보영재교육원 > 교육소개
  },

  // 교육활동 메뉴 관련 상수
  EDU_ACTIVE: {
    SCIENCE_INFO_EDU: {
      OID: '1SqySLo1000',
      NAME: '과학정보교육', // 2depth
    },
    INFO_GENIUS_EDU: {
      OID: '1SqySLo1001',
      NAME: '정보영재교육', // 2depth
    }
  },

  // 유튜브 영상 공개여부
  OPEN_YN: {
    Y: "Y",
    N: "N"
  },

  //유튜브 시청자 타입
  VIEWER_TYPE: {
    CHILD: "CHILD",
    ADULT: "ADULT"
  },

  GOOGLE_USER_TYPE: {

    USSF0003: { // 교직원( 기존 ) - USSF
      KEY: "TEACHER",
      LABEL: "교직원",
    },

    USSFGG04: { // 강원교직원( 교직원 신규 ) - USSF prefix가 같음.
      KEY: "TEACHER",
      LABEL: "교직원(NEIS)",
    },

    USST0001: {
      KEY: "STUDENT",
      LABEL: "학생",
    },

  },

  //youtube
  YOUTUBE_EMBED_URL: "https://www.youtube.com/embed/",       //youtube embed link
  YOUTUBE_VIEW_LINK_URL: "https://www.youtube.com/watch?v=", //youtube Video Link
  YOUTUBE_AXIOS_TIME_LIMIT: 300000,

  PORTAL_AXIOS_DEFAULT_TIMEOUT: 7000,
  PORTAL_AXIOS_DEFAULT_TIMEOUT_BASE: '00:00:00',

  // 본인 인증 (SelfCert) 타입 관련 key, label 객체
  CERT_TYPE_OBJECT: {
    M: {
      KEY: "M",
      LABEL: "휴대폰 인증"
    },
    I: {
      KEY: "I",
      LABEL: "아이핀 인증"
    },
    G: {
      KEY: "G",
      LABEL: "GPKI 인증"
    },
    A: {
      KEY: "A",
      LABEL: "관리자에 의한 인증"
    }
  },

  // 수리과학 센터 OID object
  CENTER_OID_OBJECT: {
    AREA0000001: {
      KEY: "AREA0000001",
      VALUE: "원주",
      VALUE2: "제1센터, 원주",
      NAME: "제1 수리과학정보 체험센터(원주)",
      SHORT_NAME: "제1(원주)",
      TYPE: "W",
    },
    AREA0000002: {
      KEY: "AREA0000002",
      VALUE: "춘천",
      VALUE2: "제2센터, 춘천",
      NAME: "제2 수리과학정보 체험센터(춘천분원)",
      SHORT_NAME: "제2(춘천)",
      TYPE: "C",
    },
    AREA0000003: {
      KEY: "AREA0000003",
      VALUE: "동해",
      VALUE2: "제3센터, 동해",
      NAME: "제3 수리과학정보 체험센터(동해분원)",
      SHORT_NAME: "제3(동해)",
      TYPE: "D",
    },
    ADMIN: {
      KEY: "INTGR000001",
      VALUE: "통합관리자",
    }
  },

  // 수리과학 공간 OID object
  SPACE_OID_OBJECT : {
    SPACE000001 : {
      KEY : "SPACE000001",
      VALUE : "수학체험공간",
      BIND_CLASS : "label-math"
    },
    SPACE000002 : {
      KEY : "SPACE000002",
      VALUE : "과학체험공간",
      BIND_CLASS : "label-science"
    },
    SPACE000003 : {
      KEY : "SPACE000003",
      VALUE : "소프트웨어교육체험공간",
      BIND_CLASS : "label-info"

    },
  },

  MANAGER_MAIN_PAGE_URL: {

    PORTAL00000: "/portal/manager/portalMgmt/memberMgmt/general/general_list",
    HOMEPAGE000: "/portal/manager/homepageMgmt/youtube/youTube_list",
    GOOGLE00000: "/portal/manager/portalMgmt/memberMgmt/general/general_list",
    ETC: "/portal/manager/iframeMgmt/managerIframe?menuOid=",
    MAM00000000: "/portal/manager/mamMgmt/appMgmt/app_list",
    NOTE0000000: "/portal/manager/gwNoteMgmt/classificationMgmt/classificationMgmt",
    APPLY000000: "/portal/manager/onlinerequestMgmt/topicMgmt/topicMgmt_list",

  },

  //-------------------------
  // 통계에서 사용
  AREA_CODE: {
    GANGWON: "A033"                                // "강원특별자치도" 지역 코드
  },

  AREA_NONE: "없음",
  AREA_EXTERNAL_GANGWON: "강원특별자치도 외",
  AREA_INTERNAL_GANGWON: "강원특별자치도",

  // KT 크로샷 관련 상수
  KTXROSHOT_MMS_CONTENT_LIMIT_BYTE: 4000,      // MMS 본문의 BYTE 제한
  KTXROSHOT_SEND_LIMIT_BY_DAY: 1000,           // MMS 하루 전송 제한 갯수
  KTXROSHOT_SEND_LIMIT_BY_MONTH: 10000,        // MMS 월 전송 제한 갯수

  // 앱 배포 상태에서 사용하는 상수
  APP_DISTRIBUTE_STATUS: {
    RELEASE: {
      KEY: "RELEASE",
      VALUE: "배포"
    },

    NOT_USE: {
      KEY: "NOT_USE",
      VALUE: "배포중지"
    }
  },

  APP_STATUS_TYPE: {

    VERSION_UPDATE: {
      KEY: "U",
      VALUE: "버전업데이트"
    },

    DELETE: {
      KEY: "X",
      VALUE: "삭제"
    },

    MODIFY: {
      KEY: "M",
      VALUE: "수정"
    },

    DETAIL: {
      KEY: "D",
      VALUE: "상세"
    },

  },

  APP_OS_TYPE: {

    ANDROID: {
      KEY: "A",
      VALUE: "apk"
    },

    IOS: {
      KEY: "I",
      VALUE: "ipa"
    }

  },

  OS_TYPE: {

    PC: {
      KEY: "P",
      VALUE: "PC",
    },

    MOBILE: {
      KEY: "M",
      VALUE: "MOBILE",
    }

  },

  // 앱 통계에서 사용하는 상수
  STATS_OS_TYPE: {
    ANDROID: {
      KEY: "A",
      VALUE: "Android"
    },

    IOS: {
      KEY: "I",
      VALUE: "IOS"
    }
  },

  // 이름 중복 CHECK
  NAME_VALIDATION: {
    INIT: "init",
    VALIDATE: "validate",
    NOT_VALIDATE: "notValidate",
    EXISTED_APP_NAME: "existedAppName",
    EXISTED_DOMAIN_NAME: "existedDomain",
  },

  // NEIS 개인번호 관련 안내 메시지
  NEIS_INFO_MESSAGE: {

    NEIS_NUM_INPUT: {
      KEY: "INPUT_NO",
      LABEL: "NEIS 개인 번호를 입력해주세요.",
    },

    NEIS_NUM_VAL: {
      KEY: "VAL_NO",
      LABEL: "NEIS 개인 번호는 영문 대문자, 숫자 포함 10자리로 입력해주세요.",
    },

    NEIS_NUM_EXIST: {
      KEY: "EXIST_NO",
      LABEL: "사용 중인 개인 번호입니다. 다른 코드를 입력해주세요.",
    },

    NEIS_NUM_AVAIL: {
      KEY: "AVAIL_NO",
      LABEL: "사용 가능한 개인 번호입니다.",
    },

  },

  PORTAL_INTEGRATED_SUCCESS_YN: {
    DELETE: {
      KEY: "D",
      VALUE: "삭제"
    }
  },

  /**
   * 관리자 / 사용자 공통 컴포넌트 사용시, 어디서 들어왔는지 체크하기 위함
   * 예) 일정 캘린더 TheMainCalendar.vue 에서 사용
   */
  COMPONENT_FROM: {
    ADMIN: 'a',
    USER: 'u'
  },

  WORKSPACE_TYPE: {

    STUDENT: "std",
    TEACHER: "tea",
    DEVICE: "dev",

  },

  /**
   * 교직원 타입의 경우 구글워크스페이스 계정 생성 상태
   * 관리자 > 회원그룹관리 > 도내 교직원 정보 조회에서 사용
   *
   */
  GOOGLE_INSERT_STATUS_FLAG: {
    SUCCESS: {
      KEY: "S",
      VALUE: "성공"
    },
    FAIL: {
      KEY: "F",
      VALUE: "실패"
    },
    WAIT: {
      KEY: "W",
      VALUE: "대기"
    },
    NONE: {
      KEY: "N",
      VALUE: "생성안함"
    },
  },

  /**
   * [관리자] 혼합형 메뉴 > 탭순서 이동시 사용
   */
  MOVE: {
    UP: "U",
    DOWN: "D",
  },

  /**
   * 모바일 사이즈
   */
  MOBILE_SIZE: {
    WIDTH: 1024,
  },

  /**
   * 모두톡 사용자 이력 > 등록, 수정 flag값
   */
  MODUTALK_SYNC_ACTION_FLAG: {
    INSERT: {
      KEY: "I",
      VALUE: "등록"
    },
    UPDATE: {
      KEY: "U",
      VALUE: "수정"
    },
    DELETE: {
      KEY: "D",
      VALUE: "삭제"
    }
  },

  // 관리자 > 교육포털 > 회원 그룹 관리 내 엑셀 타입
  USER_EXCEL_TYPE: {
    STAFF    : 'staff',
    GENERAL  : 'general',
    BLOCK    : 'block',
    DORMANT  : 'dormant',
    SECESSION: 'secession',
  },

  // 관리자 > 게시판 설정 > 첨부파일 용량 제한
  BOARD_PROPERTY: {
    FILE_LIMIT_TYPE: {
        NONE : 'NONE',
        GB   : 'GB',
        SELF : 'SELF'
    }
  },

  // SNS 타입
  SNS_TYPE_FLAG: {
    SNS_TYPE_FLAG_KAKAO: {
      KEY: "SNS_TYPE_FLAG_KAKAO",
      VALUE: "KAKAO",
      NAME: "카카오",
      ICON_IMG: "icon_kakao.png",
    },
    SNS_TYPE_FLAG_NAVER: {
      KEY: "SNS_TYPE_FLAG_NAVER",
      VALUE: "NAVER",
      NAME: "네이버",
      ICON_IMG: "icon_naver.svg",
    },
    SNS_TYPE_FLAG_GOOGLE: {
      KEY: "SNS_TYPE_FLAG_GOOGLE",
      VALUE: "GOOGLE",
      NAME: "구글",
      ICON_IMG: "icon_google.svg",
    },
    SNS_TYPE_FLAG_APPLE: {
      KEY: "SNS_TYPE_FLAG_APPLE",
      VALUE: "APPLE",
      NAME: "애플",
      ICON_IMG: "",
    },
    SNS_TYPE_FLAG_ONEPASS: {
      KEY: "SNS_TYPE_FLAG_ONEPASS",
      VALUE: "ONEPASS",
      NAME: "교육디지털원패스",
      ICON_IMG: "icon_pass.png",
    },
  },

  // SNS 프로세스 타입
  SNS_PROCESS_TYPE: {
    CONNECT : "connect",
    DISCONNECT : "disconnect",
  },

  // 통합로그인 모드
  SSO_MODE : "Y" === process.env.SSO_MODE_YN,

}

export default ( context, inject ) => {

  inject( "constant", PORTAL_CONSTANT )

};
