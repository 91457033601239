<template>
  <div class="wrapper">
    <!-- 로딩 컨테이너 -->
    <div class="main-container">
      <!-- header -->
      <!--test header-->
      <the-header2 :isMain="isMainPage"/>
      <!--메인-->
      <!--      <the-header :isMain="isMainPage"/>-->
      <!-- content-section -->
      <section class="content-section">
        <h2 class="hidden">
          컨텐츠 섹션
        </h2>
        <Nuxt/>
      </section>
      <!-- footer -->
      <the-footer :is-main="isMainPage"/>

      <the-frequent-menu/>

      <!-- 관리자, 교직원 manual btn -->
      <div
        class="manual-area"
        v-if=" isGwStaff || isManager "
        :class="isGwStaff ? 'is-gwStaff' : '' "
      >
        <div class="icon-btn">
          <span class="material-icons">menu_book</span>
        </div>
        <div class="btn btn-manual">
          <a href="/template/강원교육포털(사용자)매뉴얼.pdf" download>
            <span>사용자 매뉴얼</span>
          </a>
        </div>
        <div class="btn btn-manual work-space">
          <a href="/template/구글워크스페이스_계정_생성_및_관리_매뉴얼.pdf" download>
            <span>구글워크스페이스 매뉴얼</span>
          </a>
        </div>
        <div class="btn btn-manual" v-if="isManager && !isGwStaff">
          <a href="/template/강원교육포털(관리자)매뉴얼.pdf" download>
            <span>관리자 매뉴얼</span>
          </a>
        </div>
      </div>

      <!-- 사용자 매뉴얼 -->
      <div type="button" class="manual-btn" v-if="!isManager && !isGwStaff">
        <a href="/template/강원교육포털(사용자)매뉴얼.pdf" download>
          <span class="material-icons">menu_book</span>
          <span>사용자 매뉴얼</span>
        </a>
      </div>

      <!-- top-btn -->
      <div class="floating-btn top">
        <button type="button" class="btn-top" @click="goTop()">
          <span class="material-icons">expand_less</span>
          <span>TOP</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {elementScrollIntoView} from 'seamless-scroll-polyfill';
import TheHeader2 from "~/components/portal/user/layout/TheHeader2";
import TheFooter from "~/components/portal/user/layout/TheFooter";
import TheFrequentMenu from "~/components/portal/user/layout/TheFrequentMenu";

// TheFooter
export default {
  components: {TheFrequentMenu, TheHeader2, TheFooter},
  data() {
    return {
      // layout class
      containerLayoutClass: "",
      isMainPage: false,

    }
  },

  computed: {

    ...mapGetters( {
      loginUserInfo: "common/login/loginUserInfo",
    } ),

    isManager() {
      return this.loginUserInfo.isManager;
    },

    isGwStaff() {
      return this.$constant.USER_TYPE.STAFF_GANGWON.KEY === this.loginUserInfo.userType;
    }

  },

  beforeCreate() {
    this.$util.eventUiUtils.importMaterialWithSafari();

  },

  created() {
    // 특정 해상도 html font-size 조정
    if ( this.$route.path !== '/portal/user/login/user_login' ) {
      document.querySelector( "html" ).classList.remove( "is-media" );
    }
  },

  mounted() {
    // layout class
    this.setLayoutClass( this.$route.path );

    // attribute
    this.setOperatingSystemAttribute();

    // 스크롤
    const header = document.querySelector( ".header" );
    const topButton = document.querySelector( ".floating-btn" );
    const footer = document.querySelector( ".footer-item-box" );

    const headerHeight = header.offsetHeight;
    const topButtonHeight = topButton.offsetHeight;

    window.onscroll = function () {
      const windowTop = window.scrollY;
      const footerOffsetBottom = Math.floor( footer.getBoundingClientRect().top );
      const windowHeight = window.innerHeight;

      if ( windowTop >= headerHeight ) {
        topButton.classList.add( "is-active" );

        // [ 모바일 ] 스크롤을 내리다가 탑버튼의 위치가 푸터와 같을 때 푸터에 고정 됩니다.
        if ( window.innerWidth <= 1024 && windowHeight - topButtonHeight
          >= footerOffsetBottom ) {
          topButton.classList.add( "is-mob" );
        }
        else {
          topButton.classList.remove( "is-mob" );
        }
      }
      else {
        topButton.classList.remove( "is-active" );
      }
    };
  },

  watch: {
    $route() {
      // layout class
      this.setLayoutClass( this.$route.path );

      this.mediaClassRemove( this.$route.path );
    },
  },
  methods: {
    setLayoutClass( path ) {
      // 페이지 이동시 스크롤 위치 고정되는 이슈로 추가
      document.body.scrollTop = 0;

      this.isMainPage = false;
      this.containerLayoutClass = "sub";

      // 메인페이지만 스크롤 적용
      if ( path.includes( "user_main" ) ) {
        this.containerLayoutClass = "main";
        this.isMainPage = true;
      }
    },

    setOperatingSystemAttribute() {
      const systemName = this.getOs();

      if ( this.$validate.isEmpty( systemName ) || "unknown" === systemName  ) {
        return;
      }

      document.documentElement.setAttribute( systemName, "true" );
    },

    getOs() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;

      if (/Android/i.test(userAgent)) {
        return "android";
      }
      else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return "ios";
      }
      else if ( /Mac/i.test(platform) || /Linux/i.test(platform) ) {
        return "mac";
      }
      else if (/Win/i.test(platform)) {
        return "windows";
      }
      else {
        return "unknown";
      }
    },

    // 특정 해상도 html font-size 조정
    mediaClassRemove( path ) {
      if ( path.includes( "user_login" ) ) {
        document.querySelector( "html" ).classList.add( "is-media" );
      }
      else {
        document.querySelector( "html" ).classList.remove( "is-media" );
      }
    },

    goTop() {
      if ( this.isMobile ) {
        window.scrollTo( {top: 0, behavior: 'smooth'} )
      }
      else {
        elementScrollIntoView( document.querySelector( '#__nuxt' ), {
          behavior: 'smooth',
        } );
      }
    },
  }
}
</script>
<style scoped>
</style>
