<template>
  <div class="frequent-menu" :class="isClear" @mouseleave="setMenu">
    <div class="frequent-menu__img" @click="onViewMenu()">
      <img src="@/assets/images/portal/icon/main/icon_frequent.png" alt="자주찾는서비스">
    </div>

    <!--   차주찾는서비스 탭   -->
    <ul class="frequent-menu__tabs">
      <!--   학생 탭   -->
      <li :class="[ frequentType.STUDENT.KEY, frequentType.STUDENT.KEY === isType ? 'is-active' : '' ]"
          @mouseover="onUserType(frequentType.STUDENT.KEY)">
        <span class="material-icons">navigate_before</span>
        <span class="text" v-text="frequentType.STUDENT.LABEL">학생</span>
      </li>

      <!--   학부모 탭   -->
      <li :class="[ frequentType.PARENT.KEY, frequentType.PARENT.KEY === isType ? 'is-active' : '' ]"
          @mouseover="onUserType( frequentType.PARENT.KEY )">
        <span class="material-icons">navigate_before</span>
        <span class="text" v-text="frequentType.PARENT.LABEL">학부모</span>
      </li>

      <!--   일반 탭   -->
      <li :class="[ frequentType.GENERAL.KEY, frequentType.GENERAL.KEY === isType ? 'is-active' : '' ]"
          @mouseover="onUserType( frequentType.GENERAL.KEY )">
        <span class="material-icons">navigate_before</span>
        <span class="text" v-text="frequentType.GENERAL.LABEL">일반</span>
      </li>

      <!--   교직원 탭   -->
      <li :class="[ frequentType.STAFF.KEY, frequentType.STAFF.KEY === isType? 'is-active' : '' ]"
          @mouseover="onUserType( frequentType.STAFF.KEY )">
        <span class="material-icons">navigate_before</span>
        <span class="text" v-text="frequentType.STAFF.LABEL">교직원</span>
      </li>

      <!--   로그인 탭   -->
      <li v-if="$validate.isEmpty( loginUserInfo.userOid )"
          :class="[ frequentType.LOGIN.KEY, frequentType.LOGIN.KEY === isType ? 'is-active' : '' ]"
          @mouseover="onUserType( frequentType.LOGIN.KEY )">
        <a :href="loginUrl">
          <img src="@/assets/images/portal/icon/main/icon_login.png" alt="로그인으로 이동"/>
        </a>
      </li>

      <!--   끄기 탭   -->
      <li class="clear" @click="onHideMenu()" @mouseover="onUserType('')">
        <span class="material-icons">clear</span>
      </li>
    </ul>

    <!--   탭 메뉴   -->
    <div v-if="$validate.isNotEmpty( isType ) && frequentType.LOGIN.KEY !== isType" class="frequent-menu__overlay"
         :class="overlayStyle">
      <!--   학생   -->
      <ul v-if="frequentType.STUDENT.KEY === isType">
        <li
          v-for="( item, index ) in studentList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   학부모   -->
      <ul v-if="frequentType.PARENT.KEY === isType">
        <li
          v-for="( item, index ) in parentList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   일반   -->
      <ul v-if="frequentType.GENERAL.KEY === isType">
        <li
          v-for="( item, index ) in generalList "
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>

      <!--   교직원   -->
      <ul v-if="frequentType.STAFF.KEY === isType">
        <li
          v-for="( item, index ) in staffList"
          :key="index"
        >
          <div @click="goLink( item )">
            <span v-text="item.title"></span>
            <span class="point-txt" v-text="item.subTitle"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

const URL_MATH = process.env.SYSTEM.MATH.URL;
const URL_SURVEY = process.env.SYSTEM.SURVEY.URL;
const URL_PROTUTOR = process.env.SYSTEM.PROTUTOR.URL;
const URL_APPLY = process.env.SYSTEM.APPLY.URL;
const URL_CONTEST = process.env.SYSTEM.CONTEST.URL;
const URL_EBOOK = process.env.SYSTEM.EBOOK.URL;
const URL_NOTE = process.env.SYSTEM.NOTE.URL;
const URL_LMS = process.env.SYSTEM.LMS.URL;

const SYSTEM_TYPE = "S"; // 다른 시스템
const HOMEPAGE_TYPE = "H"; // 포털 홈페이지

const FREQUENT_TYPE = {
  STUDENT : {
    KEY : "student",
    LABEL : "학생",
  },
  PARENT : {
    KEY : "parent",
    LABEL : "학부모",
  },
  GENERAL : {
    KEY : "general",
    LABEL : "일반",
  },
  STAFF : {
    KEY : "staff",
    LABEL : "교직원",
  },
  LOGIN : {
    KEY : "login",
    LABEL : "로그인",
  },
};

export default {
  name: "TheFrequentMenu",
  data() {
    return {
      isClear: "",
      isType: "",

      frequentType : FREQUENT_TYPE,

      studentList: [
        {
          title: "e학습터",
          subTitle: "온라인 수업 듣기",
          href: "https://cls.edunet.net/",
          type: SYSTEM_TYPE,
        },
        {
          title: "설문 참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 신청",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "통합도서관시스템",
          subTitle: "전자 도서관 이용",
          href: "https://lib.gwe.go.kr/",
          type: SYSTEM_TYPE,
        },
        {
          title: "독서로",
          subTitle: "온라인 독후 활동",
          href: "https://read365.edunet.net/",
          type: SYSTEM_TYPE,
        },
        {
          title: "정보영재교육원",
          subTitle: "영재교육원 알아보기",
          href: this.$constant.MENU_URL.CENTER_GENIUS,
          type: HOMEPAGE_TYPE,
        },
      ],
      parentList: [
        {
          title: "설문참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 예약",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "통합도서관시스템",
          subTitle: "전자 도서관 이용",
          href: "https://lib.gwe.go.kr/",
          type: SYSTEM_TYPE,
        },
        {
          title: "독서로",
          subTitle: "온라인 독후활동",
          href: "https://read365.edunet.net/",
          type: SYSTEM_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "조직 정보 안내",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ],
      generalList: [
        {
          title: "공지사항",
          subTitle: "우리원 공지사항 안내",
          href: `/portal/user/common/commonBoard_list?menuOid=1SqydbhO000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "설문 참여",
          subTitle: "안내된 설문에 참여",
          href: `${URL_SURVEY}/survey/sso/sso_answer_login?location=/survey/list/surveyAnswerList`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "안내된 행사 등에 참가 신청",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "입찰 정보",
          subTitle: "우리원 입찰 정보 안내",
          href: `/portal/user/common/commonBoard_list?menuOid=1SqydbhO005`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "조직 정보 안내",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ],
      staffList: [
        {
          title: "GW노트+",
          subTitle: "수업에 필요한 노트 제작",
          href: `${URL_NOTE}/note/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 참가 신청",
          subTitle: "참가자 모집",
          href: `${URL_APPLY}/apply/sso/sso_login`,
          type: SYSTEM_TYPE,
        },
        {
          title: "보결전담강사 예약",
          subTitle: "보결 강사 예약",
          href: `${URL_PROTUTOR}`,
          type: SYSTEM_TYPE,
        },
        {
          title: "온라인 심사",
          subTitle: "서류 제출 받아 심사",
          href: `${URL_CONTEST}`,
          type: SYSTEM_TYPE,
        },
        {
          title: "원격교육연수",
          subTitle: "정보화 원격연수 수강",
          href: `${URL_LMS}/lms/sso/sso_loginWithType?type=OM`,
          type: SYSTEM_TYPE,
        },
        {
          title: "교직원 집합연수 일정",
          subTitle: "오프라인 연수 일정",
          href: `/portal/user/common/commonScreen?menuOid=1TRvaqvX01E`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "블렌디드러닝 연수",
          subTitle: "찾아가는 교육정보화 연수 수강",
          href: `${URL_LMS}/lms/sso/sso_loginWithType?type=CM`,
          type: SYSTEM_TYPE,
        },
        {
          title: "설문(참여/제작)",
          subTitle: "설문제작, 참여",
          href: `/portal/user/landing/user_landing?survey=Y`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "수리과학정보 체험센터",
          subTitle: "체험센터 예약",
          href: `${URL_MATH}/math/sso/sso_login/`,
          type: SYSTEM_TYPE,
        },
        {
          title: "전자책 출판",
          subTitle: "문서를 전자책으로 변환",
          href: `${URL_EBOOK}`,
          type: SYSTEM_TYPE,
        },
        {
          title: "교육연구",
          subTitle: "연구학교/교육자료 조회",
          href: `/portal/user/common/commonBoard_list?menuOid=1Sqy55lw000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "T-검정",
          subTitle: "연구학교 등 통계분석(T-검정)",
          href: `/portal/user/eduResearch/school/edu_school`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "업무 자료실",
          subTitle: "나이스, 정보원 자료 조회",
          href: `/portal/user/common/commonBoard_list?menuOid=1Sr9pUiq000`,
          type: HOMEPAGE_TYPE,
        },
        {
          title: "우리원 조직 안내",
          subTitle: "정보원 담당자 조회",
          href: `/portal/user/centerGuide/organization/organization_info`,
          type: HOMEPAGE_TYPE,
        },
      ]
    }
  },

  computed: {
    ...mapGetters( {
      loginUserInfo: "common/login/loginUserInfo",
    } ),

    overlayStyle() {

      if ( this.$validate.isEmpty( this.isType ) || FREQUENT_TYPE.LOGIN.KEY === this.isType ) {
        return "";
      }

      return this.isType;
    },

    loginUrl() {
      return this.$constant.SSO_MODE ? `${ process.env.SYSTEM.ACCOUNTS.URL }/v1/login/login?sys=portal&eox=${ window.location.href }` : "/portal/user/login/user_login";
    },

    // 로그인이 되어 있는지 여부
    isLoggedIn() {
      return this.$validate.isNotEmpty( this.loginUserInfo.userOid )
    },

    // 관리자면 true
    isAdmin() {
      return this.$constant.USER_TYPE.ADMIN.KEY === this.loginUserInfo.userType;
    },

    // 학생이면 true
    isStudent() {
      return this.$constant.USER_TYPE.STUDENT.KEY === this.loginUserInfo.userType || this.isAdmin;
    },

    // 강원교직원이면 true
    isGangWonStaff() {
      return this.$constant.USER_TYPE.STAFF_GANGWON.KEY === this.loginUserInfo.userType || this.isAdmin;
    },

    // 일반교직원이면 true
    isStaff() {
      return this.$constant.USER_TYPE.STAFF.KEY === this.loginUserInfo.userType || this.isAdmin;
    },
  },

  mounted() {

    window.addEventListener( 'scroll', this.onScroll );

  },

  methods: {
    onViewMenu() {
      this.isClear = ""

      return this.isClear;
    },

    onHideMenu() {
      this.isClear = "is-clear";

      return this.isClear;
    },

    onScroll() {
      if ( 1024 >= window.innerWidth ) {
        this.isType = "";
      }
    },

    onUserType( type ) {

      this.isType = type;
      return this.isType;
    },

    setMenu() {
      this.isType = "";
    },

    goLink( item ) {
      if ( this.$validate.isEmpty( item ) ) {
        return;
      }

      // 타 시스템일 경우 새창
      if ( SYSTEM_TYPE === item.type ) {
        this.goOtherSystem( item );
      }
      // 포털 시스템일 경우 router
      else if ( HOMEPAGE_TYPE === item.type ) {
        this.goHomePage( item );
      }

    },

    goOtherSystem( item ) {

      // 노트일 경우
      if ( item.href.includes( URL_NOTE ) ) {
        this.goGwNote();
      }
      // 전자책일 경우
      else if ( item.href.includes( URL_EBOOK ) ) {
        this.goEBook();
      }
      else {
        window.open( item.href );
      }
    },

    // 노트로 이동
    goGwNote() {

      // 로그인이 되어 있고 강원 교직원이 아닐 경우
      if ( this.isLoggedIn && !this.isGangWonStaff ) {
        this.$util.rsAlertUtils.rsAlert( { title : "강원 교직원 회원만 이용이 가능합니다.", contents : "", state : "warning" } );
        return;
      }

      // SSO_MODE 가 아닐 경우 sso_login로 이동
      if ( !this.$constant.SSO_MODE ) {
        window.open( URL_NOTE + "/note/sso/sso_login" );
        return;
      }

      // SSO_MODE 일 경우
      // 로그인이 안되어 있을 경우
      if ( !this.isLoggedIn ) {
        window.open( `${process.env.SYSTEM.ACCOUNTS.URL}/v1/login/login?sys=note&eox=${URL_NOTE}` );
      }
      // 노트 메인 화면으로 이동
      else {
        window.open( URL_NOTE + "/gwnote/main/userMain" );
      }
    },

    // 전자책으로 이동
    goEBook() {

      // 로그인이 되어 있고 교직원이 아닐 경우
      if ( this.isLoggedIn && !( this.isGangWonStaff || this.isStaff ) ) {
        this.$util.rsAlertUtils.rsAlert( { title : "교직원 회원만 이용이 가능합니다.", contents : "", state : "warning" } );
        return;
      }

      // SSO_MODE가 아닐 경우
      if ( !this.$constant.SSO_MODE ) {
        window.open( URL_EBOOK );
        return;
      }

      // SSO_MODE일 경우
      // 로그인하지 않았을 경우 accounts 로 이동
      if ( !this.isLoggedIn ) {
        window.open( `${process.env.SYSTEM.ACCOUNTS.URL}/v1/login/login?sys=ebook&eox=${URL_EBOOK}` );
      }
      // 노트 메인 화면으로 이동
      else {
        window.open( URL_EBOOK );
      }
    },

    goHomePage( item ) {

      // 교직원 > 설문(참여/제작) 일 경우 새창으로 띄웁니다.
      if ( item.href.includes( '/portal/user/landing/user_landing' ) ) {

        const origin = window.location.origin;
        window.open( `${origin}${item.href}` );
        return;
      }

      this.$router.push( item.href )
    },
  }
}
</script>

<style scoped>

</style>
